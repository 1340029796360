import { PanelPlugin, FieldColorModeId } from '@grafana/data'
import { SimpleOptions } from './types'
import { SimplePanel } from './components'

export const plugin = new PanelPlugin<SimpleOptions>(SimplePanel)
  .useFieldConfig({
    standardOptions: {
      color: {
        defaultValue: {
          mode: FieldColorModeId.ContinuousGrYlRd,
        },
      },
    },
  })

