import React, { useEffect, useRef } from 'react'
import { PanelProps } from '@grafana/data'
import { getDataSourceSrv } from '@grafana/runtime'
import { SimpleOptions } from '../../types'
const $ = require('jquery')
$.DataTable = require('datatables.net-dt')
import 'datatables.net'
import 'datatables.net-dt/css/jquery.dataTables.css'
import 'datatables.net-buttons'
import 'datatables.net-buttons-dt'
import JSZip from 'jszip'

interface Props extends PanelProps<SimpleOptions> { }

export function SimplePanel(props: Props) {
  const dataTableRef = useRef(null)
  const targets: any = props.data.request?.targets[0]
  
  useEffect(() => {
    initDataTable()

    return () => {
      destroyDataTable()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [targets])
  
  const initDataTable = () => {
    const dataSourceSrv: any = getDataSourceSrv()

    const url = dataSourceSrv.settingsMapByUid[targets.datasource?.uid].url

    const params = targets.params.map((item: any) => {
      let val = item[1]
      return item[0] + '=' + dataSourceSrv.templateSrv.replace(val)
    })

    const data_url = url + '?' + params.join('&')
    const columns_url = data_url.replace('_data', '_header')

    fetch(columns_url)
      .then((response) => response.json())
      .then((data) => {
        const columns = data.map((column: string, index: number) => ({
          title: column,
          data: index,
        }))

        $(dataTableRef.current).DataTable({
          ajax: data_url,
          columns: columns,
          processing: true,
          paging: true,
          serverSide: true,
          dom: 'Bltipr',
          buttons: [
            {
              text: 'Descargar en CSV',
              action: function (e: any, dt: any, node: any, config: any) {
                const separator = ','
                let zipFile: JSZip = new JSZip()
                let url = data_url
                let i = 0
                let nextDownloadData = true
                let downloadData = []

                const iteration = 500
                dt.ajax.params()['length'] = iteration
                downloadData.push(columns.map((column: any) => column.title).join(separator))
                do {
                  dt.ajax.params()['start'] = i
                  $.ajax({
                    url: url + '&' + $.param(dt.ajax.params()),
                    method:  'GET',
                    async:   false,
                    cache:   false
                  })
                    .done(function (response: any) {
                      downloadData.push(response.data.join('\n'))
                      if(response.data.length < iteration) {
                        nextDownloadData = false
                      }
                    })
                  i = i + iteration
                } while (nextDownloadData === true)
                zipFile.file('report.csv', downloadData.join('\n'))
    
                zipFile.generateAsync(
                  {
                    type: 'blob',
                    streamFiles: true,
                    compression: 'DEFLATE',
                    compressionOptions: {
                      level: 9
                    }
                  }, function updateCallback(metadata: any) {
                    console.log('progression: ' + metadata.percent.toFixed(2) + ' %')
                    if(metadata.currentFile) {
                      console.log('current file = ' + metadata.currentFile)
                    }
                  }
                )
                  .then(function (content: any) {
                    //@ts-ignore
                    saveAs(content, 'report.zip')
                  })
              }
            }
          ]
        })
      })
  }

  const destroyDataTable = () => {
    const dataTable = $(dataTableRef.current).DataTable()
    if ($.fn.DataTable.isDataTable(dataTable)) {
      dataTable.destroy()
    }
  }

  return (
    <div>
      <table ref={dataTableRef} className="cell-border compact stripe" />
    </div>
  )
}
